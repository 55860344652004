@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap");
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* position: */
}
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
    /* display: flex;
    flex-direction: column; */
}
main::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(90, 0, 87, 0.8);
    z-index: -1;
}
select::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: red;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.slick-track {
    display: flex !important;
    gap: 2rem !important;
    padding-top: 2rem !important;
    /* flex-direction: column !important;*/
    /* background-color: cadetblue;  */
}
.slick-list:first-child {
    /* transform: translate3d(0px, 0px, 0px) !important; */
}
.slick-list {
    padding-top: 3rem;
    height: fit-content !important;
}

@media screen and (max-width: 640px) {
    .slick-list {
        padding: 0;
        padding-top: 3rem;
    }
}
@media screen and (max-width: 499px) {
    #servicesList {
        width: 80% !important;
    }
}

button.slick-arrow.slick-next,
button.slick-arrow.slick-prev {
    display: none !important;
}

@layer base {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
    }
}
